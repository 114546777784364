var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"breadcrumbsmain",attrs:{"dark":_vm.$store.state.theme == 'dark',"data-aos":"fade-right","items":_vm.breadcrumbs,"divider":">","large":""}}),_c('FormModal',{key:_vm.modals.form.key,attrs:{"show":_vm.modals.form.show,"status":_vm.modals.form.status,"id":_vm.modals.form.id,"form_no":_vm.modals.form.form_no,"programYear":_vm.localConfig.programYear},on:{"action":($v) => _vm.modalActions($v),"swal":($v) => _vm.swalActions($v)}}),_c('DetailModal',{key:_vm.modals.detail.key,attrs:{"show":_vm.modals.detail.show,"id":_vm.modals.detail.data,"form_no":_vm.modals.detail.form_no,"programYear":_vm.localConfig.programYear},on:{"action":($v) => _vm.modalActions($v),"swal":($v) => _vm.swalActions($v)}}),_c('v-data-table',{class:`${
        _vm.$store.state.theme == 'dark' ? '' : ''
      } rounded-xl elevation-6 mx-3 pa-1`,attrs:{"data-aos":"fade-up","data-aos-delay":"200","multi-sort":"","headers":_vm.table.headers,"items":_vm.table.items,"search":_vm.table.search,"loading":_vm.table.loading.show,"loading-text":_vm.table.loading.text,"footer-props":{
      itemsPerPageText: 'Jumlah Data Per Halaman',
      itemsPerPageOptions: [10, 25, 40, -1],
      showCurrentPage: true,
      showFirstLastPage: true,
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"my-2 mx-2 mx-lg-3 align-center"},[_c('v-select',{staticClass:"mx-auto mr-lg-2 mb-2 mb-lg-0",staticStyle:{"max-width":"200px"},attrs:{"color":"success","item-color":"success","items":_vm.$store.state.programYear.options,"disabled":_vm.table.loading.show,"outlined":"","dense":"","hide-details":"","menu-props":{
            bottom: true,
            offsetY: true,
            rounded: 'xl',
            transition: 'slide-y-transition',
          },"rounded":"","label":"Tahun Program"},model:{value:(_vm.localConfig.programYear),callback:function ($$v) {_vm.$set(_vm.localConfig, "programYear", $$v)},expression:"localConfig.programYear"}}),_c('v-text-field',{staticClass:"mx-auto mb-2 mb-lg-0",attrs:{"color":"green","hide-details":"","dense":"","outlined":"","rounded":"","label":"Pencarian","placeholder":"Start type to search...","append-icon":"mdi-magnify"},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-divider',{staticClass:"mx-2 d-none d-lg-block"}),_c('div',{staticClass:"mx-auto"},[(_vm.User.role_group == 'IT' || _vm.User.role_name == 'UNIT MANAGER')?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"green white--text","disabled":_vm.table.loading.show || _vm.table.items.length == 0,"rounded":""},on:{"click":function($event){return _vm.exportExcel()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Export ")],1):_vm._e(),_c('v-btn',{staticClass:"pl-2",attrs:{"color":"info","rounded":""},on:{"click":() => {
                _vm.modals.form.show = true;
                _vm.modals.form.key += 1;
                _vm.modals.form.status = 'add';
              }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus-circle")]),_vm._v(" Tambah Data ")],1)],1)],1)]},proxy:true},{key:"item.no",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.total_farmer",fn:function({ item }){return [_vm._v(" "+_vm._s(item.total_farmer)+" Peserta ")]}},{key:"item.is_verified",fn:function({ item }){return [(item.is_verified == 1)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"success","text-color":"white","small":""}},[_vm._v(" Terverifikasi ")]):_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"error","text-color":"white","small":""}},[_vm._v(" Belum Terverifikasi ")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-menu',{attrs:{"content-class":"rounded-xl"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-2 d-flex align-stretch flex-column justify-center"},[_c('v-btn',{staticClass:"pl-1 d-flex justify-start align-center",attrs:{"color":"info white--text","rounded":"","small":""},on:{"click":() => _vm.showModal('detail', item)}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-information")]),_vm._v(" Detail ")],1),_c('v-btn',{staticClass:"pl-1 mt-1 d-flex justify-start align-center",attrs:{"color":"orange white--text","rounded":"","small":"","disabled":item.is_verified === 1},on:{"click":() => {
                _vm.showModal('form', item);
              }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-pencil-circle")]),_vm._v(" Edit ")],1)],1)],1)]}}])}),_c('ExportSosprog',{attrs:{"show":_vm.showExportSosprog,"items":this.table.items,"program_year":this.localConfig.programYear},on:{"close":function($event){_vm.showExportSosprog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }